import { useContext, useEffect, useState } from 'react'
import Mydrawer from '../component/collapse'
import { AppContext } from '../context/UserContext';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { IProfile } from '../type';
import { Txtable } from '../component/table';
import { useNavigate } from 'react-router-dom';
import { BACKEND_URL } from '../configs/config';
import Header from '../component/Header';

export default function Progress() {
    const navigate = useNavigate()
    const appContext = useContext(AppContext);
    const [profile, setProfile] = useState<IProfile>();

    const getProfileByPaymentAddress = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/profile/getByPaymentAddress?paymentAddress=${appContext?.paymentAddress}`);
            if (response.data.success) {
                // console.log(response.data.payload);
                return response.data.payload
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            throw error;
        }
    };

    const fetchSpecificProfiles = async () => {
        try {
            const data = await getProfileByPaymentAddress();
            setProfile(data);
            console.log(data);
        } catch (error) {
            // Display an error message to the user
        }
    };

    useEffect(() => {
        console.log("profileid ==> ", appContext?.profileId);
        fetchSpecificProfiles();
    }, []);


    return (
        <div className="flex w-full min-h-screen mx-auto ">
            <div className="flex flex-col w-full min-h-screen">

                {/* user profile */}
                <div className="flex flex-row justify-between px-4 py-8 ">
                    <div className="flex flex-row items-center min-[566px]:hidden">
                        <Mydrawer />
                        <img src="../assets/Frame.png" />
                    </div>
                    <div className="max-[563px]:hidden"></div>

                    {/* Header */}
                    <Header />
                </div>

                {/* Title */}
                <div className='flex flex-row justify-between px-8 '>
                    <p className="bold text-[32px] text-white leading-10">In Progress</p>
                    <div></div>
                </div>

                {/* table */}
                <div className="flex flex-col justify-between gap-6 px-8 py-6">
                    <Txtable flag={'process'} />
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}


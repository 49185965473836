import React from "react"
import { ThreeCircles } from 'react-loader-spinner'
export const Loading = () => {
  return (
    <div className="absolute top-0 left-0 flex items-center justify-center w-screen min-h-screen">
      <div className="absolute z-20">
        <ThreeCircles
          visible={true}
          height="100"
          width="100"
          color="#074EB3"
          ariaLabel="three-circles-loading" />
      </div>
      <div className="absolute z-10 w-screen h-screen bg-black bg-opacity-80"></div>
    </div>
  )
}

import { useState, useEffect, useRef, useContext } from "react";
import { AppContext } from "../context/UserContext";
import axios from 'axios';
import { IoCopyOutline } from "react-icons/io5";
import { IAddress, IProfile } from "../type"
import { BiPlus } from "react-icons/bi";
import { Loading } from "../component/loading"
import Mydrawer from "../component/collapse";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";

import { FaRegCircleUser } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import { IoLogOutOutline } from "react-icons/io5";
import { BACKEND_URL, BACKEND_URL_PURE } from "../configs/config";


export const Users = () => {
    const navigate = useNavigate();
    const appContext = useContext(AppContext)

    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [username, setUsername] = useState('');
    const [walletName, setWalletName] = useState('');
    const [avatar, setAvatar] = useState({
        preview: "",
        raw: ""
    });
    const [profiles, setProfiles] = useState<IProfile[]>([]);
    const [profile, setProfile] = useState<IProfile>();
    const [headerFlag, setHeaderFlag] = useState(false);


    const getProfileByPaymentAddress = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/profile/getByPaymentAddress?paymentAddress=${appContext?.paymentAddress}`);
            if (response.data.payload) {
                appContext?.updateProfileId(response.data.payload._id);
            }
            setProfile(response.data.payload)
        } catch (error) {
            throw error;
        }
    };

    const getAllProfiles = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/profile/getAll`);
            if (response.data.success) {
                return response.data.payload;
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            throw error;
        }
    };

    const fetchProfiles = async () => {
        try {
            const data = await getAllProfiles();
            setProfiles(data);
        } catch (error) {
            console.log(error);
        }
    };

    const init = async () => {
        await getProfileByPaymentAddress();
        await fetchProfiles();

        setIsLoading(false);
    }

    useEffect(() => {
        init()
    }, []);

    const handleChange = (e: any) => {
        setUsername(e.target.value)
    }
    const handleName = (e: any) => {
        setWalletName(e.target.value)
    }
    const handleAvatarUpload = (e: any) => {
        if (e.target.files.length) {
            setAvatar({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
            });
        }
    }

    const submitFunction = async () => {

        if (!username) {
            toast.warn("username is required")
        } else if (!walletName) {
            toast.warn("walletName is required")
        } else if (!avatar.raw) {
            toast.warn("avatar is required")
        } else {

            const formData = new FormData();
            formData.append('image', avatar.raw);
            const uploadData = await axios.post(`${BACKEND_URL_PURE}/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((res): any => {

                    return res.data;
                });

            if (uploadData[0].path) {
                const requestBody = {
                    username: username,
                    walletName: walletName,
                    avatar: uploadData[0].path,
                    paymentAddress: appContext?.paymentAddress,
                    paymentPubkey: appContext?.paymentPublickey,
                    ordinalsAddress: appContext?.ordinalsAddress,
                    ordinalsPubkey: appContext?.ordinalsPublickey,
                    walletType: appContext?.walletType
                };
                console.log(requestBody);
                
                // Make an AJAX request to the server to submit the form data using Axios
                try {
                    const response = await axios.post(`${BACKEND_URL}/profile/saveProfile`, requestBody, {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                    setProfiles(response.data.payload)
                    await getProfileByPaymentAddress();

                    setShowModal(false);
                    setUsername("")
                    setWalletName("")
                    setIsLoading(false);
                }

                catch (error: any) {
                    toast.error(error.message)
                    setShowModal(false);
                };
            }
        }
    }
    const handleCopyClick1 = async () => {
        try {
            await navigator.clipboard.writeText(`${appContext?.paymentAddress}`);

            toast.success("address was copied!");

        } catch (err) {

            toast.warn("Not enough NOME tokens");
            alert("Copy to clipboard failed.");
        }
    };
    const handleCopyClick = async (address: IAddress) => {
        try {
            await navigator.clipboard.writeText(`${address.paymentAddress}`);

            toast.success("The Address was copied!");

        } catch (err) {

            alert("Copy to clipboard failed.");
        }
    };

    const closeHandle = async () => {
        setShowModal(false);
        avatar.preview = "";
    }
    const handleProfile = (profileId: string) => {
        console.log('CLicked profile id===>', profileId);
        if (profile?._id == profileId) {
            navigate('/profile')
        } else {
            toast.warn('This is not your profile!')
        }
    }
    const handleLogoutClick = () => {

        navigate("/")
        localStorage.clear();
        appContext?.updatePaymentAddress("");
    }
    const addProfile=()=>{
        setShowModal(true)
        console.log(appContext?.ordinalsPublickey);
    }

    if (isLoading) {
        return (
            <div className="flex w-full min-h-screen">
                <div className="flex flex-col w-full">
                    <Loading />
                </div>
            </div>
        )
    }

    return (
        <div className="flex w-full min-h-screen">

            <div className="flex flex-col w-full">
                {/* header */}
                <div className="flex flex-row justify-between px-4 py-8 ">
                    <div className="flex flex-row items-center min-[566px]:hidden">
                        <Mydrawer />
                        <img src="../assets/Frame.png" />
                    </div>
                    <div className="max-[563px]:hidden"></div>
                    <div className="relative flex flex-col max-[570px]:flex-row min-[450px]:bg-[#1d2027] border-[#26292e] broder-1 items-center px-2 py-1 rounded-xl gap-2 drop-shadow-2xl border-2 pr-8" onClick={() => setHeaderFlag(flag => !flag)}>
                        <div className="flex flex-row">
                            {profile ? (<img src={`${BACKEND_URL_PURE}/${profile?.avatar}`} className="rounded-[9px] h-10 w-10" alt="Newbie" />) : (<img src="../assets/guest.png" className="w-8 h-8" />)}
                            <div className="flex flex-col pl-2 justify-center gap-1 max-[450px]:hidden">
                                {profile ? (<p className="text-white font-semibold text-[16px] leading-4">{profile?.username}</p>) :
                                    (<p className="text-white font-semibold text-[12px] leading-4">Guest</p>)}
                                <div className="flex flex-row duration-300 cursor-pointer hover:brightness-200" onClick={handleCopyClick1}>
                                    <p className="truncate text-[12px] font-bold text-[#637592] max-w-[90px] leading-4">{appContext?.paymentAddress} </p>
                                    <IoCopyOutline style={{ color: "#637592" }} className="w-3.5 h-3.5" />
                                </div>
                            </div>
                        </div>
                        {headerFlag ?
                            <div className="absolute mt-12 left-0 right-0 rounded-[12px] py-2 flex flex-col bg-[#1d2027] border-t-2 border-[#26292e] text-white drop-shadow-xl">
                                {/* <div className="flex flex-row p-2 pl-5 gap-4 items-center hover:bg-[#212429] hover:brightness-125 duration-300 cursor-pointer">
                                    <FaRegCircleUser size={20} />
                                    <p className="text-[16px] font-semibold">Wallet Settings</p>
                                </div>
                                <div className="flex flex-row p-2 pl-5 gap-4 items-center  hover:bg-[#212429] hover:brightness-125 duration-300 cursor-pointer">
                                    <FaPlus size={20} />
                                    <p className="text-[16px] font-semibold">Add wallet</p>
                                </div> */}
                                <div className="flex flex-row p-2 pl-5 gap-4 text-red-600  items-center  hover:bg-[#212429] hover:brightness-125 duration-300 cursor-pointer"
                                onClick={handleLogoutClick}
                                >
                                    <IoLogOutOutline size={20} />
                                    <p className="text-[16px] font-semibold">Log out</p>
                                </div>
                            </div> : <></>}
                    </div>
                </div>


                {/* user */}
                <div className="flex flex-col gap-6 px-4">
                    <div className="flex flex-row justify-between w-full">
                        <p className="font-bold text-[32px] leading-10 text-white">Leaderboard</p>
                        <button className="flex flex-row bg-[#191D24] text-white items-center px-4 py-2 rounded-xl  border-[#FFFFFF] border-2 cursor-pointer hover:brightness-200 duration-300"
                            onClick={() => addProfile()}>
                            <p className="pr-2"><BiPlus /></p>
                            <label className="text-white text-[14px] leading-6 max-[450px]:hidden cursor-pointer">Add Profile</label>
                        </button>
                    </div>
                    {showModal ? (
                        <>
                            <div className="fixed inset-0 flex items-center ">
                                <div className="mx-auto border-2 border-gray-700 border-solid rounded-xl">
                                    <div className="bg-[#252B35] flex flex-row justify-between px-4 py-5 border-b-2 border-solid border-gray-700">
                                        <div></div>
                                        <h3 className="text-[20px] font-semibold font-menrope text-white leading-[30px]">Add Profile</h3>
                                        <button onClick={() => closeHandle()}>
                                            <img src="../assets/close.png" />
                                        </button>
                                    </div>
                                    <form encType='multipart/form-data'>

                                        <div className="bg-[#252B35]">
                                            <div className="bg-[#252B35] gap-4  rounded-xl px-4 py-3 w-full">
                                                <label className="block text-[14px] leading-6 text-[#637592]  mb-1">
                                                    Username
                                                </label>
                                                <input className="bg-[#16171B]  rounded w-full  placeholder:text-gray-600 py-2 px-4 text-white focus:outline-none"
                                                    value={username}
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder="Username" />
                                                <label className="block text-[14px] text-[#637592] pt-3 mb-1 gap-4">
                                                    Wallet Name
                                                </label>
                                                <input className="bg-[#16171B]  rounded w-full  placeholder:text-gray-600 py-2 px-4 text-white focus:outline-none"
                                                    value={walletName}
                                                    onChange={(e) => handleName(e)}
                                                    placeholder="Wallet name" />
                                                <label className="block text-[14px] text-[#637592] pt-3 mb-1 gap-4">Avatar</label>
                                                <div className="flex items-center justify-center w-[360px] h-[180px]">
                                                    <input name="image"
                                                        type="file"
                                                        id="upload-button"
                                                        style={{ display: 'none' }}
                                                        accept="image/*"
                                                        onChange={handleAvatarUpload} />
                                                    <label htmlFor="upload-button">
                                                        {avatar.preview ? (
                                                            <img
                                                                src={avatar.preview}
                                                                alt="dummy"
                                                                width="100px"
                                                                height="100px"
                                                                className="mx-5 my-10"
                                                            />
                                                        ) : (
                                                            <>
                                                                <img src="../assets/Input.png" className="w-full text-left text-white text-1xl" />
                                                                <div />
                                                            </>
                                                        )}
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="flex bg-[#252B35] justify-center pb-3 px-3.5">
                                            <button
                                                className="bg-[#1665FF] rounded-xl px-6 py-3 w-full hover:bg-blue-700"
                                                type="button"
                                                onClick={submitFunction}
                                            ><p className="text-[14px] text-white font-semibold leading-6">Create Profile</p></button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </>
                    ) : null}
                    {/* profile */}
                    <div className="flex flex-col gap-2 overflow-auto">
                        {profiles.length ?
                            profiles.map((profile: IProfile, index) => (
                                <div key={"profiles" + index} className="flex flex-row max-[862px]:flex-col min-[862px]:items-center border-gray-700 border-2 justify-between bg-[#252B35] p-2 rounded-lg">
                                    <div className="flex flex-row pl-2 items-center max-[862px]:justify-start max-[862px]:gap-2 gap-2">
                                        <div className="flex">
                                            <img src={`${BACKEND_URL_PURE}/${profile.avatar}`} className="w-10 h-10" />
                                        </div>
                                        <div className="flex flex-col">
                                            <p className="text-white text-[24px] cursor-pointer hover:underline" onClick={() => handleProfile(profile._id)} >{profile.username}</p>
                                            <div className="flex flex-wrap gap-2">
                                                {profile.address.map((address: IAddress, index: number) => (
                                                    <div className="flex flex-row items-center duration-300 cursor-pointer hover:brightness-200" onClick={() => handleCopyClick(address)} >
                                                        <p key={"p" + index} className="text-[#637592] text-manrope truncate max-w-[80px] font-semibold">
                                                            {address.paymentAddress}
                                                        </p>
                                                        <IoCopyOutline style={{ color: "#637592" }} className="w-3.5 h-3.5" />&nbsp;
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="flex flex-row max-[862px]:justify-between gap-20">
                                        <div className="flex-col gap-4 text-white">
                                            <p className="font-manrope text-[16px] text-gray-400">Point</p>
                                            <p className="font-manrope text-[20px] font-semibold">0</p>
                                        </div>
                                        <div className="flex-col gap-4 text-white">
                                            <p className="font-manrope text-[16px] text-gray-400">Ranked</p>
                                            <div className="flex flex-row text-[20px] font-semibold">
                                                <p>#{index+1} / </p><p className="text-[#637592]">&nbsp;{profiles.length}</p>
                                            </div>
                                        </div>
                                        <div className="flex-col text-white">

                                        </div>

                                    </div>
                                </div>
                            )) :
                            <div className="flex justify-center">
                                <p className="text-2xl text-red-700">No data</p>
                            </div>}
                    </div>

                </div>
            </div>
            <ToastContainer />
        </div>
    );
}




import React from 'react'
import { useState } from 'react'
// import component 👇
import Drawer from 'react-modern-drawer'

//import styles 👇
import 'react-modern-drawer/dist/index.css'
import { Link, Outlet, useNavigate } from "react-router-dom"



const Mydrawer = () => {

    const menuOptions1 = [
        {
            icon: '../assets/document-add.png',
            label: 'Draft Transaction',
            link: '/draft-transaction',
        },
        {
            icon: '../assets/document-text.png',
            label: 'In Progress',
            link: '/in-progress',
        },
        {
            icon: '../assets/chart-bar.png',
            label: 'Mempool',
            link: '/mempool',
        },
        {
            icon: '../assets/lightning-bolt.png',
            label: 'Speed Up(RBF)',
            link: '/rbf',
        },
        {
            icon: '../assets/paper-airplane.png',
            label: 'Send',
            link: '/send',
        },
        {
            icon: '../assets/sm-view-grid-add.png',
            label: 'Portfolio',
            link: '/portfolio',
        },
        {
            icon: '../assets/user.png',
            label: 'Profile',
            link: '/profile/:id',
        },
        {
            icon: '../assets/chart-bar.png',
            label: 'Leaderboard',
            link: '/users',
        },
        {
            icon: '../assets/bell.png',
            label: 'Notifications',
            link: '/notificatoins',
        },
        {
            icon: '../assets/bell.png',
            label: 'Notifications',
            link: '/notificatoins',
        },
        {
            icon: 'https://ordinals.com/static/rune.svg',
            label: 'Rune Mint',
            link: '/runemint',
        },
    ];

    const menuOptions2 = [
        {
            icon: '../assets/cog.png',
            label: 'Settings',
            link: '/settings',
            color: 'text-white',
            onClick: () => handleSettingsClick(),
        },
        {
            icon: '../assets/support.png',
            label: 'Help',
            link: '/help',
            color: 'text-white',
            onClick: () => handleHelpClick(),
        },
        {
            icon: '../assets/logout.png',
            label: 'Logout',
            link: '/logout',
            color: 'text-[#FF7D7D]',
            onClick: () => handleLogoutClick(),
        },
    ];
    const handleClick = () => { }
    const handleHelpClick = () => { }
    const handleSettingsClick = () => { }
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = React.useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }
    const [menuIndex, setMenuIndex] = useState(-1);

    const handleLogoutClick = () => {
        navigate("/")
        localStorage.clear();
    }


    return (
        <>
            <button onClick={toggleDrawer}><img src='../assets/menu.png' /></button>
            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='left'
                className=''
            >
                <div>
                    <div className="flex flex-row ">

                        <div className="flex flex-col bg-[url(./assets/Welcome.png)]   min-w-[236px] min-h-screen pr-[16px]  pl-[16px]"
                            onClick={handleClick}>
                            {/* logo */}
                            <div className="flex flex-row items-center mx-auto ">
                                <img src="../assets/Frame.png" alt="logo" />
                            </div>
                            <div className="flex flex-row items-start mx-auto ">
                                <img src="../assets/Frame1.png" alt="logo" />
                            </div>
                            {/* mainsidebar */}
                            <div className="flex flex-col  max-h-[532px] gap-1">
                                {menuOptions1.map((option, index) =>
                                    menuIndex !== index ?
                                        (<Link to={option.link} onClick={() => setMenuIndex(index)} key={"link" + index}>
                                            <div key={index} className="flex flex-row justify-start  pr-[16px] pb-[16px] pt-[12px] pl-[12px]  hover:bg-[#4665FF] hover:rounded-xl focus:bg-[#1665FF] ">
                                                <button className="flex items-center flex-row gap-2 ">
                                                    <img src={option.icon} alt="logo" />
                                                    <p className="text-white text-[16px] leading-6 font-semibold font-manrope ">
                                                        {option.label}
                                                    </p>
                                                </button>
                                            </div>
                                        </Link>
                                        ) :
                                        (<Link to={option.link} onClick={() => setMenuIndex(index)} key={"link2" + index}>
                                            <div key={index} className="flex flex-row justify-start pr-[16px] pb-[16px] pt-[12px] pl-[12px] rounded-xl  hover:bg-[#1665FF] focus:bg-[#1665FF] bg-[#1665FF]">
                                                <button className="flex items-center flex-row gap-2 ">
                                                    <img src={option.icon} alt="logo" />
                                                    <p className="text-white text-[16px] leading-6 font-semibold font-manrope">
                                                        {option.label}
                                                    </p>
                                                </button>
                                            </div>
                                        </Link>)
                                )}
                            </div>

                            {/* others */}
                            <div className="flex flex-col max-w-[204px] max-h-[152px]  mt-auto mb-3">
                                {menuOptions2.map((option, index) => (
                                    menuIndex !== index ? (
                                        <div key={index} className="flex flex-row justify-start  pr-[16px] pt-[12px] pb-[12px] pl-[16px]  hover:bg-[#4665FF] hover:rounded-xl focus:bg-[#1665FF] "
                                            onClick={option.onClick}
                                        >
                                            <button
                                                className="flex items-center flex-row gap-2"
                                            >
                                                <img src={option.icon} />
                                                <p className={`${option.color} text-[16px] leading-6 font-semibold font-manrope `}>
                                                    {option.label}
                                                </p>
                                            </button>
                                        </div>) : (
                                        <div key={index} className="flex flex-row justify-start  pr-[16px] pt-[12px] pb-[12px] pl-[16px]  rounded-xl bg-[#1665FF] "
                                            onClick={option.onClick}
                                        >
                                            <button
                                                className="flex items-center flex-row gap-2"
                                            >
                                                <img src={option.icon} />
                                                <p className={`${option.color} text-[16px] leading-6 font-semibold font-manrope `}>
                                                    {option.label}
                                                </p>
                                            </button>
                                        </div>
                                    )

                                ))}
                            </div>
                        </div>

                        <Outlet />
                    </div>
                </div>
            </Drawer>
        </>
    )
}

export default Mydrawer



export const NoTransaction = () => {
    return (
        <div className="flex  bg-[#191D24] items-center justify-center h-[550px]">

            <div className="flex flex-col w-full">
                <div className="flex flex-col items-center justify-center gap-3">
                    
                    <div className="flex flex-col items-center gap-2">
                        <p className="text-2xl font-semibold leading-7 text-white">No Transaction</p>
                        <p className="text-[16px] font-normal leading-4 text-[#637592]"> Your added transactions will shown here </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/UserContext";
import { IProfile } from "../type";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import Mydrawer from "../component/collapse";
import { Txtable } from "../component/table";
import { BACKEND_URL } from "../configs/config";
import Header from "../component/Header";


export const Rbf = () => {

    const appContext = useContext(AppContext)
    const [profile, setProfile] = useState<IProfile>();

    const getProfileByPaymentAddress = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/profile/getByPaymentAddress?paymentAddress=${appContext?.paymentAddress}`);
            setProfile(response.data.payload)
            console.log(profile);
        } catch (error) {
            throw error;
        }
    };
    useEffect(() => {
        getProfileByPaymentAddress();
    }, []);

    return (
        //   RBF part

        <div className="flex w-full min-h-screen gap-2 mx-auto ">
            <div className="flex flex-col w-full ">

                {/* user profile */}
                <div className="flex flex-row justify-between px-4 py-8 ">
                    <div className="flex flex-row items-center min-[566px]:hidden">
                        <Mydrawer />
                        <img src="../assets/Frame.png" alt="Image"/>
                    </div>
                    <div className="max-[563px]:hidden"></div>
                    <Header />
                </div>

                {/* hearder */}
                <div className='flex flex-row justify-between px-8'>
                    <p className="bold text-[32px] text-white leading-10">Speed Up(RBF)</p>
                    <div></div>
                </div>

                {/* table */}
                <div className="flex flex-col justify-between gap-6 px-2 py-6 border-none">
                    <Txtable flag={'draft'} />
                </div>
            </div>
            <ToastContainer />
        </div>



    )


}
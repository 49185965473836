import { useContext, useEffect, useState } from 'react'
import Notiflix from "notiflix";
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button, Slider, Table, Pagination, Input } from '@mantine/core';
import axios from 'axios';
import { MdOutlineManageSearch } from "react-icons/md";

import Mydrawer from '../component/collapse'
import { AppContext } from '../context/UserContext';
import { ToastContainer } from 'react-toastify';
import { IRuneList } from '../type';
import Header from '../component/Header';
import { BACKEND_URL } from '../configs/config';


export default function RuneMint() {

    const appContext = useContext(AppContext);

    const [opened, { open, close }] = useDisclosure(false);
    const [runelist, setRuneList] = useState<IRuneList[]>();
    const [selectedRune, setSelectedRune] = useState<IRuneList>();
    const [totalCount, setTotalCount] = useState(0);
    const [mintCount, setMintCount] = useState(1);
    const [mintFlag, setMintFlag] = useState(false);
    const [searchWord, setSearchWord] = useState("");
    const [page, setPage] = useState(1);

    const fetchRuneList = async (searchWord: string = "", startIndex: number = 0) => {
        const url = `${BACKEND_URL}/utils/getRuneList`;
        const payload: any = (await axios.post(url, { searchWord, startIndex: (startIndex - 1) * 10 })).data.payload;
        console.log("fetchRuneList ==> ", payload);

        if (payload) {
            // setRuneList(payload);
            let tempRuneList: IRuneList[] = [];
            payload.list.map((elem: any) => {
                tempRuneList.push({
                    Rune: elem.spacedRune,
                    Runeid: elem.runeid,
                    sixHourMints: elem.sixHourMints,
                    transactions: elem.transactions,
                    progress: ((elem.supply - elem.premine) * 100 / (elem.terms.amount * elem.terms.cap)).toFixed(2),
                    holders: elem.holders
                })
            })
            setRuneList(tempRuneList);
            setTotalCount(Math.ceil(payload.totalCount / 10));
        }
    }

    const openMintModal = async (elem: IRuneList) => {
        setSelectedRune(elem);
        open();
    }

    const onMintFunc = async () => {
        try {
            Notiflix.Loading.hourglass("Minting runes...");
            setMintFlag(true);
            console.log("runeMintCount ==> ", mintCount);
            const url = `${BACKEND_URL}/rune/pre-rune-mint`;
            const payload = {
                receiver: appContext?.ordinalsAddress, runeId: selectedRune?.Runeid, count: mintCount
            };
            const preMintResult = (await axios.post(url, payload)).data;
            console.log("pre mint result ==> ", preMintResult);

            const { payAddress, amount, feeRate, orderId } = preMintResult.payload;
            const txId = await (window as any).unisat.sendBitcoin(payAddress, amount, {
                feeRate
            })

            const url2 = `${BACKEND_URL}/rune/after-rune-mint`;
            const payload2 = {
                orderId,
                paymentAddress: appContext?.paymentAddress,
                txId
            }
            const savedResult = (await axios.post(url2, payload2)).data;
            console.log("savedResult ==> ", savedResult);
            Notiflix.Loading.remove();
        } catch (error) {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Cancel signing..");
            setMintFlag(false);
        }
    }

    useEffect(() => {
        fetchRuneList();
    }, [])

    useEffect(() => {
        fetchRuneList(searchWord);
        console.log("searchWord ==> ", searchWord);
    }, [searchWord])

    useEffect(() => {
        fetchRuneList(searchWord, page);
        console.log("page ==> ", page);
    }, [page])

    return (
        <div className="flex w-full min-h-screen mx-auto ">
            <div className="flex flex-col w-full min-h-screen">

                {/* user profile */}
                <div className="flex flex-row justify-between px-4 py-8 ">
                    <div className="flex flex-row items-center min-[566px]:hidden">
                        <Mydrawer />
                        <img src="../assets/Frame.png" alt="Image"/>
                    </div>
                    <div className="max-[563px]:hidden"></div>

                    {/* Header */}
                    <Header />
                </div>

                {/* Title */}
                <div className='flex flex-row justify-between px-8 '>
                    <p className="bold text-[32px] text-white leading-10">Rune Mint</p>
                    <div></div>
                </div>

                {/* table */}
                {runelist ?
                    <div className='flex flex-col mx-6 mt-10 gap-4 min-w-[1000px] overflow-auto'>
                        <Input className='w-80' placeholder="Rune ticker" leftSection={<MdOutlineManageSearch />} onChange={(e) => setSearchWord(e.target.value)} />
                        <Table className='text-white' withTableBorder>
                            <Table.Thead>
                                <Table.Tr>
                                    <Table.Th>Rune</Table.Th>
                                    <Table.Th>6h</Table.Th>
                                    <Table.Th>Progress</Table.Th>
                                    <Table.Th>Transactions</Table.Th>
                                    <Table.Th>Holders</Table.Th>
                                    <Table.Th>Mint</Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                                {runelist.map((element) => (
                                    <Table.Tr key={element.Rune}>
                                        <Table.Td className='flex flex-row gap-2 items-center'>
                                            <img alt='rune icon' src={`https://api-t2.unisat.io/icon-v1/icon/runes/${element.Rune}`} className='w-6 h-6'></img>
                                            <div>{element.Rune}</div>
                                        </Table.Td>
                                        <Table.Td>{Number(element.sixHourMints).toLocaleString()}</Table.Td>
                                        <Table.Td className='w-40 flex flex-col justify-center mx-0'>
                                            <div className='mx-auto'>{Number(element.progress).toLocaleString()}%</div>
                                            <div className="h-2 w-full bg-gray-800 relative overflow-hidden">
                                                <div
                                                    className="absolute inset-0 left-0 bg-yellow-500"
                                                    style={{
                                                        width: `${Math.floor(Number(element.progress))}%`, // Dynamically set the width
                                                    }}
                                                ></div>
                                            </div>
                                        </Table.Td>
                                        <Table.Td>{Number(element.transactions).toLocaleString()}</Table.Td>
                                        <Table.Td>{Number(element.holders).toLocaleString()}</Table.Td>
                                        <Table.Td>
                                            {/* <div className='flex py-2 justify-center w-[50px] rounded-xl bg-yellow-600 text-white cursor-pointer' >
                                                Mint
                                            </div> */}
                                            <Button variant="outline" loading={opened} onClick={() => openMintModal(element)}>
                                                Mint
                                            </Button>
                                        </Table.Td>
                                    </Table.Tr>
                                ))}
                            </Table.Tbody>
                        </Table>
                        <Pagination total={totalCount} autoContrast color="lime.4" value={page} onChange={setPage} className='mt-4 flex mx-auto' />
                    </div> : <></>}
            </div>
            <Modal opened={opened} onClose={close} centered withCloseButton={false} >
                {/* Modal content */}
                {selectedRune ?
                    <div className="flex flex-col gap-2 bg-[#191D24] mx-auto border-solid border-[#252B35] text-white">
                        <p className='text-white font-bold text-xl text-center mt-4'>Rune Mint</p>
                        <div className='flex flex-col gap-4 p-6 '>
                            <div className='flex flex-col gap-2'>
                                <div className='w-full flex flex-col justify-center mx-0 gap-2 p-4 rounded-lg bg-gray-800'>
                                    <div className='w-full flex flex-row justify-between'>
                                        <p className=''>Mint Progress</p>
                                        <p>{Number(selectedRune.progress).toLocaleString()}%</p>
                                    </div>
                                    <div className="h-2 w-full bg-gray-700 relative overflow-hidden">
                                        <div
                                            className="absolute inset-0 left-0 bg-gray-500"
                                            style={{
                                                width: `${Math.floor(Number(selectedRune.progress))}%`, // Dynamically set the width
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full flex flex-row justify-between mx-0 gap-2 p-4 rounded-lg bg-gray-800'>
                                <p>Rune / Rune Id</p>
                                <p>{selectedRune.Rune}</p>
                            </div>
                            <div className='flex flex-col mb-10 p-4 rounded-lg bg-gray-800'>
                                <div className='w-full flex flex-row justify-between'>
                                    <p className=''>Mint Progress</p>
                                    <p>{mintCount}</p>
                                </div>
                                <Slider
                                    color="blue"
                                    marks={[
                                        { value: 20, label: '20' },
                                        { value: 50, label: '50' },
                                        { value: 80, label: '80' },
                                    ]}
                                    max={100}
                                    min={1}
                                    value={mintCount}
                                    onChange={setMintCount}
                                    className='mb-4'
                                />
                            </div>
                            <Button loading={mintFlag} variant="filled" color='indigo' onClick={onMintFunc}>Mint</Button>
                        </div>
                    </div> : <></>}
            </Modal>
            <ToastContainer />
        </div>
    )
}


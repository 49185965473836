import { useContext, useEffect, useState } from "react";
import { IProfile } from "../type";
import axios from "axios";
import Mydrawer from "../component/collapse";
import { AppContext } from "../context/UserContext";
import { ToastContainer } from "react-toastify";
import { PieChart } from "react-minimal-pie-chart";
import { BACKEND_URL } from "../configs/config";

import { FaBtc } from "react-icons/fa6";
import { IoLogoUsd } from "react-icons/io5";
import { Loading } from "../component/loading";
import Header from "../component/Header";

export const Portfolio = () => {
    
    const appContext=useContext(AppContext);
    const [profile, setProfile] = useState<IProfile>();
    const [btcFlag, setBtcFlag]=useState(false);
    const [totalDraft, setTotalDraft] = useState<number>(456);
    const [progressTx, setProgressTx] = useState<number>(7);
    const [brc, setBrc] = useState<number>(0)
    const [ordinal, setOrdinal] = useState<number>(0);
    const [bitcoin, setBitcoin] = useState<number>(0)
    const [rune, setRune] = useState<number>(0)
    const [catagory, setCatagory] = useState('BTC');
    const [BTCPrice, setBTCPrice] = useState(1);

    const [isloading, setIsLoading] = useState(true);

    const getProfileByPaymentAddress = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/profile/getByPaymentAddress?paymentAddress=${appContext?.paymentAddress}`);
            if (response.data.success) {
                // console.log(response.data.payload);
                return response.data.payload
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            throw error;
        }
    };

    const fetchSpecificProfiles = async () => {
        try {
            const data = await getProfileByPaymentAddress();
            setProfile(data);
            console.log(data);
        } catch (error) {
            // Display an error message to the user
        }
    };

    const fetchBTCPrice = async () => {
        const url = `${BACKEND_URL}/portfolio/getBTCPrice`;
        const btc = await axios.get(url);
        console.log("BTC price ==> ", btc.data);
        setBTCPrice(btc.data.payload);
    }

    useEffect(() => {
        fetchSpecificProfiles();
        fetchPortfolio();
        fetchBTCPrice();
    }, []);

   
    const fetchPortfolio = async () => {
        try {
            const url = `${BACKEND_URL}/portfolio/getAll`;
            console.log("url ==> ", url);

            const response = await axios.get(url);

            if (response.data.success) {
                // Data retrieval successful
                console.log('All portfolio:', response.data.payload);
                const payload = response.data.payload;

                setBitcoin((payload.totalBTC / (10 ** 8)));
                setOrdinal(payload.totalInscription / (10 ** 8));
                setBrc(payload.totalBRC20 / (10 ** 8));
                setRune(payload.totalRune / (10 ** 8));
                setTotalDraft(payload.draftCount);
                setProgressTx(payload.process);

                setIsLoading(false);
                // You can use the data retrieved here to update your frontend UI
            } else {
                // Handle the case where no profiles exist
                console.log('No profiles exist:', response.data.message);
            }

        } catch (error) {
            // Handle any errors that occurred during the fetch
            console.error('Error fetching profiles:', error);
        }
    }
    
    if (isloading) {
        return (
            <div className="flex w-full min-h-screen">
                <div className="flex flex-col w-full">

                    <Loading />
                </div>
            </div>
        )
    }
    
    return (

        <div className="flex w-full min-h-screen mx-auto ">
            <div className="flex flex-col w-full ">
                {/* header */}
                <div className="flex flex-row justify-between px-4 py-8 ">
                    <div className="flex flex-row items-center min-[566px]:hidden">
                        <Mydrawer />
                        <img src="../assets/Frame.png" />
                    </div>
                    <div className="max-[563px]:hidden"></div>
                    <Header />
                </div>

                {/* portfolio hearder */}
                <div className="flex flex-row justify-between">
                    <p className="bold text-[32px] text-white leading-10">Portfolio</p>
                </div>

                {/* border */}
                <div className="border-b-1 border-solid border-slate-700 max-w-[1140px]"></div>

                {/* chart */}
                <div className="flex flex-row flex-wrap justify-center gap-6 pt-20 " >
                    <div className="flex flex-col max-[1150px]:flex-row gap-6 ">
                        <div className="flex flex-col items-center  max-[1150px]:w-[220px] w-[270px] max-[840px]:w-[170px] bg-[#191D24] gap-4 rounded-[16px] border-2 border-[#252B35] p-4">
                            <img src="../assets/plus.png" className="h-[44px] w-[44px]" alt="Image"/>
                            <p className="bold text-[40px] leading-[50px] text-[white]">{totalDraft}</p>
                            <p className="leading-6 text-[16px] font-normal text-[#637592]">Total draft</p>
                        </div>
                        <div className="flex flex-col items-center  max-[1150px]:w-[220px] w-[270px] max-[840px]:w-[170px]  bg-[#191D24] gap-4 rounded-[16px] border-2 border-[#252B35] p-4">
                            <img src="../assets/db.png" className="h-[44px] w-[44px]" alt="Image"/>
                            <p className="bold text-[40px] leading-[50px] text-[white]">{progressTx}</p>
                            <p className="leading-6 text-[16px] font-normal text-[#637592]">In Progress</p>
                        </div>
                    </div>
                    <div className="flex flex-col bg-[#191D24]  p-4 gap-4 rounded-[16px] border-2 border-[#252B35] ">
                        <div className="flex flex-row items-center justify-between">
                            <p className="bold text-[20px] leading-7.5 text-white max-[840px]:text-[16px] max-[840px]:leading-6 ">Total Balance</p>
                            <button className="relative flex flex-row items-center font-manrope text-xl max-[840px]:text-lg leading-4.5 text-white pl-8 max-[840px]:pl-1  pr-2 py-2 max-[840px]:py-0 gap-2 rounded-xl border-2 border-[#637592]"
                                onClick={() => setBtcFlag(flag => !flag)}
                            >{catagory}<img src="../assets/icon.png" className="w-[10px] h-[8px]" alt="Image"/>
                                {btcFlag ?
                                    <div className="absolute mt-12 right-0 top-0 rounded-[12px] py-2 flex flex-col bg-[#1d2027] border-t-2 border-[#26292e] text-white drop-shadow-xl">
                                        <div
                                            className="flex flex-row p-2 pl-5 gap-4 items-center justify-start hover:bg-[#212429] hover:brightness-125 duration-300 cursor-pointer"
                                            onClick={() => setCatagory('BTC')}
                                        >
                                            <FaBtc size={32} color="yellow" /><p className="text-[16px] font-semibold">BTC</p>
                                        </div>
                                        <div
                                            className="flex flex-row p-2 pl-5 gap-4 items-center justify-start hover:bg-[#212429] hover:brightness-125 duration-300 cursor-pointer"
                                            onClick={() => setCatagory('USD')}
                                        >
                                            <IoLogoUsd size={40} color="yellow" className="-ml-1" /><p className="text-[16px] font-semibold -ml-1 pr-3">USD</p>
                                        </div>
                                    </div> : <></>}
                            </button>
                        </div>
                        <div className="flex flex-row max-[840px]:flex-col items-center gap-8 pl-10 max-[840px]:pl-0 min-w-[335px]">
                            <div className=" flex w-[300px] h-[300px] max-xl:w-[200px] max-xl:h-[200px]">
                                <PieChart
                                    data={[
                                        { title: 'Three', value: rune, color: '#d41e1e' },
                                        { title: 'Two', value: brc, color: '#637592' },
                                        { title: 'Three', value: bitcoin, color: '#E78A00' },
                                        { title: 'One', value: ordinal, color: '#1665FF' },
                                    ]}
                                    lineWidth={35}
                                />;
                            </div>
                            <div className="flex flex-col gap-3 pr-2">
                                <div className="flex flex-row items-center gap-4">
                                    <div className="flex flex-row items-center gap-2">
                                        <div className="h-[10px] w-[10px] rounded-full bg-[#d41e1e]"></div>
                                        <p className="text-white text-[20px] font-normal leading-7.5 max-[840px]:text-[16px] max-[840px]:leading-6">Runes</p>
                                    </div>
                                    <p className="text-white text-[20px] font-bold leading-7.5 w-2/3 max-[840px]:text-[16px] max-[840px]:leading-6">{catagory === 'BTC' ? `${rune} BTC` : `${Math.floor(rune * BTCPrice * 1000) / 1000} USD`}</p>
                                </div>
                                <div className="flex flex-row items-center gap-4">
                                    <div className="flex flex-row items-center gap-2 ">
                                    <div className="h-[10px] w-[10px] rounded-full bg-[#1665FF]"></div>
                                        <p className="text-white text-[20px] max-[840px]:text-[16px] max-[840px]:leading-6 font-normal leading-7.5">BRC-20</p>
                                    </div>
                                    <p className="text-white text-[20px] font-bold leading-7.5 max-[840px]:text-[16px] max-[840px]:leading-6">{catagory === 'BTC' ? `${brc} BTC` : `${Math.floor(brc * BTCPrice * 1000) / 1000} USD`}</p>
                                </div>
                                <div className="flex flex-row items-center gap-4">
                                    <div className="flex flex-row items-center gap-2 ">
                                    <div className="h-[10px] w-[10px] rounded-full bg-[#E78A00]"></div>
                                        <p className="text-white text-[20px] font-normal leading-7.5 max-[840px]:text-[16px] max-[840px]:leading-6">Bitcoin</p>
                                    </div>
                                    <p className="text-white text-[20px] font-bold leading-7.5 w-2/3 max-[840px]:text-[16px] max-[840px]:leading-6">{catagory === 'BTC' ? `${bitcoin} BTC` : `${Math.floor(bitcoin * BTCPrice * 1000) / 1000} USD`}</p>
                                </div>
                                <div className="flex flex-row items-center gap-4">
                                    <div className="flex flex-row items-center gap-2 ">
                                    <div className=" h-[10px] min-w-[10px] rounded-full bg-[#637592]"></div>
                                        <p className="text-white text-[20px] font-normal leading-7.5 max-[840px]:text-[16px] max-[840px]:leading-6">Ordinals</p>
                                    </div>
                                    <p className="text-white text-[20px] font-bold leading-7.5 w-2/3 max-[840px]:text-[16px] max-[840px]:leading-6">{catagory === 'BTC' ? `${ordinal} BTC` : `${Math.floor(ordinal * BTCPrice * 1000) / 1000} USD`}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ToastContainer />
        </div>

    )
}



import { useContext, useEffect, useState } from "react";
import Mydrawer from "../component/collapse";
import { AppContext } from "../context/UserContext";
import { IFeeRate, IProfile } from "../type";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../configs/config";
import { Loading } from "../component/loading";
import Header from "../component/Header";

export default function Mempool() {
    const appContext = useContext(AppContext);

    const [profile, setProfile] = useState<IProfile>();

    const [feeRate, setFeeRate] = useState<IFeeRate>();
    const [isLoading, setIsLoading] = useState(true);

    const getProfileByPaymentAddress = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/profile/getByPaymentAddress?paymentAddress=${appContext?.paymentAddress}`);
            setProfile(response.data.payload)
        } catch (error) {
            throw error;
        }
    };
    useEffect(() => {
        getProfileByPaymentAddress();
    }, []);

    const fetchFeeRate = async () => {
        const url = `${BACKEND_URL}/utils/getFeeRate`
        const payload = await axios.get(url);

        console.log("payload.data.payload ==> ", payload.data.payload);
        setFeeRate(payload.data.payload);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchFeeRate();
    }, [])

    if (isLoading) {
        return (
            <div className="flex w-full min-h-screen">
                <div className="flex flex-col w-full">
                    <Loading />
                </div>
            </div>
        )
    }

    return (
        <div className="flex w-full h-screen overflow-auto" id="mempool-page">

            <div className="flex flex-col w-full">
                {/* header */}
                <div className="flex flex-row justify-between px-4 py-8">
                    <div className="flex flex-row items-center min-[566px]:hidden">
                        <Mydrawer />
                        <img src="../assets/Frame.png" alt="Image"/>
                    </div>
                    <div className="max-[563px]:hidden"></div>
                    <Header />
                </div>
                {/* mempool */}
                <div className="flex flex-col gap-6 px-8 py-6">

                    <div className="flex">
                        <p className="font-bold text-[32px] leading-10 text-white">Mempool</p>
                    </div>

                    {/* <div className="flex justify-center">
                        <img src="../assets/vecter.png" />
                    </div>

                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-1">
                            <div className="flex flex-row items-center gap-2">
                                <img src="../assets/cube.png" className="w-[16px] h-[18px]" />
                                <p className="text-white text-[20px] font-bold leading-7.5">Approximate Fee/vB</p>
                            </div>
                            <p className="text-[16px] leading-5 font-medium text-[#637592]">Approximate Fee/vB to place your transaction in the next mempool block</p>
                        </div>

                        <div className="flex flex-row gap-4 W-[482PX] h-[150px] max-[502px]:w-[332px] max-[502px]:h-[100px]">
                            <div className="relative flex items-center">
                                <div className="absolute left-0 right-0 flex justify-center top-6">
                                    <p className="text-xl font-bold text-white left-14 bottom-2/3">{feeRate?.minimumFee}</p>
                                </div>
                                
                                    <img src={getImageForRate(feeRate?.minimumFee)} className="" />
                               
                            </div>
                            <div className="relative flex items-center">
                                <div className="absolute left-0 right-0 flex justify-center top-6">
                                    <p className="text-xl font-bold text-white left-14 bottom-2/3">{feeRate?.hourFee}</p>
                                </div>
                                <img src={getImageForRate(feeRate?.hourFee)} className="" />
                            </div>
                            <div className="relative flex items-center">
                                <div className="absolute left-0 right-0 flex justify-center top-6">
                                    <p className="text-xl font-bold text-white left-14 bottom-2/3">{feeRate?.fastestFee}</p>
                                </div>
                                <img src={getImageForRate(feeRate?.fastestFee)} className="" />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center">
                        <img src="../assets/vecter.png" />
                    </div> */}

                    <div className="flex flex-col gap-4">
                        <div className="flex flex-row items-center gap-2">
                            <img src="../assets/react.png" className="w-[16px] h-[16px]" alt="Image"/>
                            <p className="text-white text-[20px] font-bold leading-7.5">Mempool Googles</p>
                        </div>
                        {/* <div className="bg-[#191D24] w-[232px] h-[264px] p-4 rounded-lg gap-2">
                            <img src="../assets/Block.png" className="w-[200px] h-[200px] rounded" />
                            <p className="text-white font-semibold text-[16px] leading-6">842176.bitmap</p>
                        </div> */}
                        <iframe src="https://mempool.space/mempool-block/0" allow="https://localhost:3000"></iframe>
                    </div>

                </div>
            </div>
        </div>
    )
}

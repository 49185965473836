import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import axios from 'axios';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableFooter } from '@mui/material';
import Pagination from '@mui/material/Pagination';

import { ITransaction } from '../type';
import { BACKEND_URL } from '../configs/config';
import { Loading } from './loading';
import { AppContext } from '../context/UserContext';
import { toast } from 'react-toastify';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        paddingRight: 0,
        paddingLeft: 0,
        paddingTop: 4,
        paddingBottom: 4,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 10,
        paddingRight: 0,
        paddingLeft: 0,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,

    },

}));

const StyledTableFooter = styled(TableFooter)({
    backgroundColor: '#191D24', // Set the background color of the table footer
    '& .MuiTablePagination-root': {
        color: 'white', // Set the text color of the pagination controls
    },
    border: 0,
    borderBottom: 0
});

export default function Drafttable() {

    const appContext = useContext(AppContext)

    const walletType = appContext?.walletType;
    const paymentAddress = appContext?.paymentAddress;
    const paymentPublicKey = appContext?.paymentPublickey;
    const ordinalsPublicKey = appContext?.ordinalsPublickey;

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [transactions, setTransactions] = useState<ITransaction[]>([]);
    const [rows, setRows] = useState<ITransaction[]>([]);
    const [isLoading, setIsLoading] = useState(true)
    const [err, setErr] = useState('');

    const [recommendFeeRate, setRecommendFeeRate] = useState(0);
    const [minFeeRate, setMinFeeRate] = useState(0);

    const feeRateRef = React.useRef(null);

    const handlePageChange = (event: any, value: any) => {
        setPage(value);
    };

    const getDraftTx = async () => {

        try {
            const response = await axios.get<{ success: boolean; message: string; payload: ITransaction[] }>(`${BACKEND_URL}/tx/getDraftTx`, {
                params: {
                    profileId: appContext?.profileId
                },
            });

            console.log("getDraftTx response ==> ", response.data.payload);

            if (response.data.success) {
                setTransactions(response.data.payload);
            } else {
                console.log(response.data.message);
            }
            setIsLoading(false)
        } catch (err) {
            console.error(err);
        }
    }

    const submitHandler = async () => {
        try {

            // Verify feeRate value
            let feeRateTemp = ''
            if (feeRateRef.current) {
                feeRateTemp = feeRateRef.current['value'];
            }

            if(!feeRateTemp) {
                setErr("FeeRate is required!!");
                return;
            } else {
                console.log("feeRateTemp ==> ", parseInt(feeRateTemp));
                console.log("feeRateTemp ==> ", minFeeRate);
                console.log("feeRateTemp ==> ", parseInt(feeRateTemp) > minFeeRate);

                if (parseInt(feeRateTemp) < minFeeRate){
                    setErr(`FeeRate should be over ${minFeeRate} sats/vb.`)
                    return;
                }
            }

            setErr("");

            console.log("submitHandler ==> ", transactions);
            
            const url = `${BACKEND_URL}/draft/send`;
            const feeRate = feeRateTemp
            const payload = {
                draftList: transactions,
                walletType,
                sellerPaymentAddress: paymentAddress,
                sellerPaymentPubkey: paymentPublicKey,
                sellerOrdinalPubkey: ordinalsPublicKey,
                feeRate
            }
            const result = await axios.post(url, payload);
            console.log("result ==> ", result.data);

            if (result.data.success === true) {
                const psbt = result.data.payload;
                const signedPsbt = await (window as any).unisat.signPsbt(psbt);
                const txIdList = await transactions.filter(tran => tran._id);

                const signedUrl = `${BACKEND_URL}/draft/draft-exec`;
                const signedPayload = {
                    psbt,
                    signedPsbt,
                    walletType,
                    txIdList,
                    feeRate
                }
                const signedResult = await axios.post(signedUrl, signedPayload);
                console.log("signedResult ==> ", signedResult.data);
                toast.success(signedResult.data.message);
            }
        } catch (error) {
            console.log("error ==> ", error);
        }
    }

    const getFeeRate = async () => {
        try {
            const payload = await axios.get(`${BACKEND_URL}/utils/getFeeRate`);
            if (payload.data.success) {
                const temp = payload.data.payload.fastestFee;
                const tempMinValue = payload.data.payload.minimumFee;
                setRecommendFeeRate(temp);
                setMinFeeRate(tempMinValue);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDraftTx();
        getFeeRate();
    }, [])

    if (isLoading) {
        return (
            <div className="flex w-full min-h-screen">
                <div className="flex flex-col w-full">
                    <Loading />
                </div>
            </div>
        )
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow >
                            <StyledTableCell align="left" style={{ backgroundColor: "#252B35", border: 'none', display: 'flex', paddingLeft:"4px"}}>No</StyledTableCell>
                            <StyledTableCell align="center" style={{ backgroundColor: "#252B35", border: 'none' }}>Type</StyledTableCell>
                            <StyledTableCell align="center" style={{ backgroundColor: "#252B35", border: 'none' }} className='truncat'>Destination Address</StyledTableCell>
                            <StyledTableCell align="center" style={{ backgroundColor: "#252B35", border: 'none' }} className='truncate '>Inscription ID</StyledTableCell>
                            <StyledTableCell align="center" style={{ backgroundColor: "#252B35", border: 'none', textAlign: 'center', display:'flex'}}>Amount</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {transactions.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((row, index) => (
                            <StyledTableRow key={index} style={{
                                backgroundColor: index % 2 === 0 ? '#191D24' : '#18191D',
                            }}>
                                <StyledTableCell align="left" component="th" scope="row" style={{ color: 'white', border: 'none', fontSize: '14px' }} >
                                    {index}
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ color: row.type === 'Ordinals' ? '#10A678' : '#F7931A', border: 'none', fontSize: '14px' }}>
                                    <button
                                        className={`bg-[${row.type === 'Ordinals' ? '#10A678' : '#F7931A'}] rounded-full h-[44px] w-[46px]`}
                                    ><p >{row.type}</p></button>
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ color: 'white', border: 'none', fontSize: '14px' }} className='truncate max-w-[5px]'>{row.destinationAddress}</StyledTableCell>
                                <StyledTableCell align="center" style={{ color: 'white', border: 'none', fontSize: '14px' }} className='truncate max-w-[5px]'>{row.inscriptionId}</StyledTableCell>
                                <StyledTableCell align="center" style={{ color: 'white', border: 'none', fontSize: '14px' }}>{row.amountToTransfer} BTC</StyledTableCell>
                            </StyledTableRow>
                        ))}

                    </TableBody>

                </Table>

                <div className='flex flex-row justify-between bg-[#191D24] p-2'>
                    <Pagination count={Math.ceil(rows.length / rowsPerPage)} page={page} onChange={handlePageChange}
                        style={{ backgroundColor: "#191D24" }}
                        variant="outlined"
                        shape="rounded"
                        color="primary"
                    />
                </div>

            </TableContainer>
            <div className='flex min-[1100px]:flex-row max-[1100px]:flex-col min-[1100px]:items-end max-[1100px]:items-center justify-center w-full min-[1100px]:gap-40 max-[1100px]:gap-6 mt-10'>
                <div className='relative flex flex-col'>
                    <label className="font-manrope text-[14px] leading-6 font-normal text-[#637592]">*recommended feeRate between {minFeeRate + 5} ~ {recommendFeeRate + 5} sats/vb</label>
                    <input
                        className="bg-[#16171B] rounded-xl px-4 py-3 gap-2 text-[#637592] focus:outline-none placeholder:text-gray-600"
                        name="feeRate"
                        placeholder="Input fee rate"
                        ref={feeRateRef}
                    ></input>
                    {err ? <p className="absolute bottom-0 ml-2 text-red-600 -mb-7">{err}</p> : <></>}

                </div>
                <div
                    className='flex w-[300px] h-[50px] font-bold text-white bg-blue-700 cursor-pointer text-center p-2 rounded-lg hover:brightness-150 duration-300 mt-auto'
                    onClick={() => submitHandler()}>
                    <p className='w-full my-auto text-center'>Submit</p>
                </div>
            </div>
        </>
    );
}
